import { defineStore } from 'pinia'

export const useMessageCenter = defineStore('messageCenter', {
	state: () => ({
		totalCount: 0,
	}),
	getters: {
		messageCenter() {
			return this.$state
		}
	},
	actions: {
		updateMessageCenter(payload) {
			Object.keys(payload).forEach((key) => {
				if (Object.keys(this.$state).includes(key)) {
					this.$state[key] = payload[key]
				}
			})
		}
	},
	persist: {
		storage: sessionStorage
	}
})
